'use client';
import styled from 'styled-components';

export const InfoContainer = styled.div`
  margin-top: ${props => props.theme.spacing(6)};
  display: grid;
  gap: ${props => props.theme.spacing(4)};
  grid-template-columns: repeat(1, auto);
  @media ${props => props.theme.laptop} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const PopupContainer = styled.div`
  @media ${props => props.theme.laptop} {
    display: flex;
    justify-content: space-between;
  }
`;
export const DesktopImageContainer = styled.div`
  display: none;

  @media ${props => props.theme.laptop} {
    display: flex;
    padding: ${props => props.theme.spacing(4, 0)};
  }
`;
export const MobileImageContainer = styled.div`
  overflow: hidden;

  @media ${props => props.theme.laptop} {
    display: none;
  }
`;

export const ContentContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: ${props => props.theme.spacing(4, 8, 8, 8)};
  gap: ${props => props.theme.spacing(4)};

  @media ${props => props.theme.laptop} {
    gap: ${props => props.theme.spacing(5)};
    padding: ${props => props.theme.spacing(8)};
    align-items: start;
  }
`;
export const TitlesSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacing(2)};
  text-align: center;

  @media ${props => props.theme.laptop} {
    text-align: left;
    margin-top: ${props => props.theme.spacing(6)};
  }
`;

export const StyledTitle = styled.p`
  ${props => props.theme.typography.subheading2};
  color: ${props => props.theme.colors.hero.spinach};
  font-size: 22px;
  font-weight: 500;
  line-height: 20px; /* 100% */
  letter-spacing: 0.2px;
  text-transform: uppercase;
  text-align: center;

  @media ${props => props.theme.laptop} {
    text-align: left;
  }
`;
export const StyledSubtitle = styled.p`
  width: 320px;
  font-size: 28px;
  font-style: normal;
  font-weight: bold;
  line-height: 28px; /* 125% */
  letter-spacing: 0.24px;
`;

export const QRSectionContainer = styled.div`
  display: none;

  @media ${props => props.theme.laptop} {
    display: flex;
    flex-direction: row;
    gap: ${props => props.theme.spacing(4)};
    margin-top: ${props => props.theme.spacing(4)};
  }
`;
export const QRExplanationContainer = styled.div`
  display: none;

  @media ${props => props.theme.laptop} {
    display: flex;
    flex-direction: column;
    align-self: center;
  }
`;

export const StyledQRExplanation = styled.p`
  font-size: 18px;
  letter-spacing: 0.32px;
`;

export const CTASectionContainer = styled.div`
  display: flex;
  gap: ${props => props.theme.spacing(2)};

  @media ${props => props.theme.laptop} {
    display: none;
  }
`;
